.menu{
    box-sizing: border-box;
    float: left;
    width: 5%;
    height: 100%;
    background: rgb(0, 126,188);
    padding: 1vh 0;
    font-size: 2.5vh;
    /*text-shadow: 1px 1px 2px black;*/
}

.menu .menu-point-container{
    position: relative;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menu-point{
    height: 5vw;
    /*border-top: 1px solid white;*/
    line-height: 5vw;
}

.menu-point a{
    text-decoration: none;
    color: white;
    font-weight: 900;
}

.menu-point:last-child{
    /*border-bottom: 1px solid white;*/
}

.menu img{
    box-sizing: border-box;
    width: 100%;
    padding: 1.8vh;
}