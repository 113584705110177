@import url('https://fonts.googleapis.com/css?family=Sanchez|Fira+Sans');

html{
  /*background: linear-gradient(-60deg,  rgb(121, 109, 155), rgb(190, 180, 210),rgb(121, 109, 155));*/
  /*background: linear-gradient(-30deg,  rgb(70, 8, 50), rgb(10, 10, 11),rgb(4, 4, 25));*/
  /*background: linear-gradient(-60deg,  rgb(110, 148, 101), rgb(180, 210, 187),rgb(110, 148, 101));*/
  /*background: linear-gradient(-40deg, rgb(147, 170, 78), rgb(194, 215, 129),rgb(129, 170, 41));*/
  /*background: linear-gradient(-20deg, rgb(85, 107, 24), rgb(255, 243, 105),rgb(78, 102, 17));*/
  /*background: linear-gradient(3deg, rgb(237, 135, 21), rgb(255, 246, 72),rgb(249, 145, 28));*/
  /*background: linear-gradient(3deg, rgb(70, 159, 127), rgb(135, 255, 230),rgb(65, 161, 154));*/
  /*background: linear-gradient(-70deg, rgb(0, 102, 143) 20%, rgb(0, 136, 191) 50%,rgb(0, 102, 143) 80%);*/
  /*background: linear-gradient(3deg, rgb(6, 0, 60), rgb(70, 144, 255),rgb(0, 106, 225));*/
  /*background: linear-gradient(3deg, rgb(0, 12, 80), rgb(80, 3, 255),rgb(222, 0, 255));*/
  background: linear-gradient(-60deg, rgb(77, 77, 77), rgb(184, 184, 184),rgb(77, 77, 77));
  background-size: cover;
  color: white;
  box-sizing: border-box;
}

body{
  font-family: 'Fira Sans', sans-serif;
  overflow: hidden;
  margin: 0;
}

h3{
  font-size: 2vh;
  line-height: 2vh;
}

p{
  font-size: 1vh;
  line-height: 1vh;
}

.App {
  position: relative;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.content{
  float: left;
}

.slides{
  float: left;
  width: 95%;
  height: 33%;
}

.slides .temp-slide{
  box-sizing: border-box;
  height: 100%;
  width: 33.3%;
  padding: 2%;
  padding-right: 3%;
  padding-left: 3%;
  float: left;
}

.slides .temp-slide .slide{
  background: olive;
  width: 100%;
  height: 100%;
}

.slides .temp-slide .slide img{
  width: 100%;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.temp-slide, .slick-slider{
  max-height: 100%;
}
.slick-slider img{
  width: 100%;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.numInputWrapper span.arrowUp, .numInputWrapper span.arrowDown{
  opacity: 1;
  width: 40px;
}

.numInputWrapper span.arrowUp::after, .numInputWrapper span.arrowDown::after{
  left: 37%;
}

.flatpickr-time .numInputWrapper input{
  padding-right: 33px;
}

.text-muted{
  color: #b5c0ce;
}

.text-sm{
  font-size: 0.8em;
}

.persons{
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.persons .person{
  position: relative;
  width: 3.7vw;
  margin-right: 0.8vw;
  margin-bottom: 1vw;
}

.persons .person img{
  width: 100%;
  border-radius: 100%;
}

.persons .person .person-name{
  position: absolute;
  font-size: 0.45vw;
  line-height: 0.45vw;
  bottom: -0.8vh;
  text-align: center;
  left: -20%;
  right: -20%;
}

.App{
  padding: 2%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.clock-container{
  position: absolute;
  font-size: 2vw;
  left: 2vw;
  top: 2vw;
}
.person-container{

  width: 100%;
  height: 76%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 2%;
  padding-top: 3.5%; /*8%*/
  background-clip: content-box;
}
.left-containers{
  width: 75%;
  height: 100%;
  padding-right: 2%;
  box-sizing: border-box;
  overflow: hidden;
}
.right-containers{
  width: 25%;
  height: 100%;
}
.logo-container{
  width: 100%;
  height: 10%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 2.6vh;
  line-height: 2.6vh;
}
.logo-container .company{
  font-size: 4vh;
  line-height: 4vh;
}

.logo-container .text{
  /*margin: 1.5vh;*/
  margin: 1.5vh 1.5vh 1.5vh -0.3vh;
  text-align: left;
}


.logo-container img{
  max-height: 100%;
  max-width: 100%;
}

.today-text-container{
  text-align: left;
  font-size: 1.6vh;
  height: 4%;
  padding-top: 4%;
  box-sizing: border-box;
}
.special-calendars{
  height: 86%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.today-text-container, .meeting-1-container,.meeting-2-container, .maintenance-container{
  width: 100%;
}


.meeting-container{
  
  box-sizing: border-box;
  border: 2px solid rgba(255,255,255,0.6);
  margin-bottom: 1vh;
}
.meeting-container.meeting-container-0{
  height: 25%;
}
.meeting-container.meeting-container-1{
  height: 25%;
}
.meeting-container.meeting-container-2{
  height: 50%;
}

.meeting-container:last-of-type{
  margin-bottom: 0;
}

.bottom-containers{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.slider-container,.slider2-container,.movie-container{
  height: calc(2.5 * 9vh);
  width: calc(2.5 * 16vh);
}

.slider-container{
}

.slider2-container{
}

.movie-container{
  background: darkcyan;
}


.meeting-room .name{
  text-align: left;
  font-size: 1.5vw;
  line-height: 2vw; /*1.5vw*/
  font-weight: bold;
  margin: 1vh 1vh 0;
  height: auto;
  width: 100%;
  float: none;
}

.meeting-room .title{
  text-align: left;
  font-size: 0.9vw;
  line-height: 0.9vw;
  margin: 0 1vh 1vh 1vh;
}

.meeting-room{
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.meeting-room .meeting-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.meeting-room .meeting-top .meeting-count{
  text-align: right;
  margin: 1vh 1vh 0.5vh 0;
  font-size: 1.1vh;
  line-height: 1.1vh;
}
.meeting-room .meeting-top .meeting-time{
  text-align: right;
  margin: 0 1vh;
  font-size: 1.1vh;
  line-height: 1.1vh;
}

.meetings{
  overflow: hidden;
  max-height: 100%;
}

.meeting-room .meeting{
  display: flex;
  justify-content: space-between;
  margin: 0.7vh 1vh;
  padding: 0.5vh 1vh;
  background: rgba(0,0,0,0.15);
  border-radius: 2vh;
  font-size: 0.75em;
}

.video-frame{
  width: 100%;
  height: 100%;
}

.video-frame .video{
  width: 100%;
  height: 100%;
}