.form-group{
    line-height: 38px;
    width: 100%;
    float: left;
}

.PlanForm .form-group.date{
    width: 40%;
    text-align: center;
    margin-left: 8%;
}
.PlanForm .date:last-of-type{
    margin-left: 5%;
}

.PlanForm .form-group.date label{
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.form-group label, .form-group input{
    float: left;
}

.form-group.date input{
    width: 100%;
    text-align: center;
}

.form-group input, .form-group select{
    box-sizing: border-box;
    background: linear-gradient(#fff, #fff, #fff);
    font-weight: 600;
    padding: 5px;
    width: 70%;
    margin-bottom: 6%;
}

.PlanForm{
    box-sizing: border-box;
    padding: 1.5% 2% 0% 0%;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16%;
    margin-bottom: 20%;
    background-size: cover;
    text-align: center;
    background: linear-gradient(to right,rgba(30,30,30,1) 90%,#18a782 10%);
    color: white;
    border-top-left-radius: 2vh;
}

.hidden{
    display: none;
}

.plan-more{
    font-size: 1.5vh;
    padding: 2vh;
    margin-bottom: 2vh;
    cursor: pointer;
}

.plan-more:hover{
    color:  lightseagreen;
}

.PlanForm .react-datepicker.floating{
    right: 0;
    bottom: 40px;
}

.react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::before{
    content: none;
}

.react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after{
    content: none;
}

.PlanForm  .react-datepicker-component.invalid .react-datepicker-input input{
    color: red;
}
.PlanForm .react-datepicker-component .react-datepicker-input{
    min-width: inherit;
    height: auto;
}

.PlanForm .react-datepicker-component .react-datepicker-input .button-wrapper{
    top: 1vh;
    right: 0.5vh;
}
.react-datepicker .react-datepicker-container .react-datepicker-body .react-datepicker-row .react-datepicker-picker.selected.current{
    background: #18a782;
    border-color: #18a782;
    color: #08221b;
}

.react-datepicker .react-datepicker-container .react-datepicker-top{
    background: #18a782;
    border-color: #18a782;
}

.PlanForm .react-datepicker-component .react-datepicker-input input{
    color:white;
    font-size: 0.9vh;
    margin-right: 3.5vh;
    margin-left: 0;
    text-align: left;
    background: rgb(50,50,50);
    border-radius: 0.2vh;
    padding-right: 10px;
    line-height: 1vh;
    text-shadow: 1px 1px 2px black;
}

.PlanForm .react-datepicker-component .react-datepicker-input .button-wrapper .input-button {
    color: white;
    font-size: 1vh;
    margin: 0;
}

.PlanForm .plan-profile .profile-text h3{
    margin-top: 0;
    margin-bottom: 1vh;
    font-size: 2vh;
}
.PlanForm .plan-profile .profile-text p{
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 1.5vh;
}

.PlanForm label, .PlanForm select, .PlanForm option, .PlanForm input, .PlanForm{
    font-size: 1.1vh;
    line-height: 1.1vh;
}

.PlanForm .error{
    padding: 1vw;
    color: #e9b4b4;
    font-size: 0.8vw;
    line-height: 0.8vw;
}

.plan-profile img{
    width: 50%;
    margin: auto;
    display: block;
    padding-top: 4%;
    border-radius: 50%;
}

.plan-profile .line{
    margin: auto;
    width: 25%;
    border-bottom: 1px solid white;
    margin: 8% auto;
}

.plan-profile{
    overflow: auto;
    text-align: center; 
}

.submit{
    margin: 12% 0;
    border-radius: 1vh;
    padding: 5% 20%;
    border: none;
    color: white;
    font-weight: bold;
    background: #18a782;
    font-size: 1.2vh;
    line-height: 1.2vh;
}